import { disableConnectQueue } from 'aurelia-binding';
import { Aurelia } from 'aurelia-framework';
import { TCustomAttribute } from 'aurelia-i18n';
import { PLATFORM } from 'aurelia-pal';
import { LocaleBackend } from 'locales/locale-backend';
import environment from '../config/environment.json';

import { AppRouter } from 'aurelia-router';
{
	/**
	 * Override the original AppRouter.generate function by removing leading "/"
	 * See https://github.com/aurelia/templating-router/issues/57
	 */
	(function fixGeneratedRoutes(generateOrig: Function) {
		AppRouter.prototype.generate = function(name: string, params?: any, options?: any): string {
			let href = generateOrig.call(this, name, params, options);

			if (href.charAt(0) === '/') {
				href = href.substr(1);
			}

			return href;
		}
	})(AppRouter.prototype.generate);
}

export async function configure(aurelia: Aurelia) {
  aurelia.use
    .standardConfiguration()
    .feature(PLATFORM.moduleName('resources/index'));

  aurelia.use.plugin(PLATFORM.moduleName('aurelia-i18n'), (instance: any) => {
    let aliases = ['t', 'i18n'];
    TCustomAttribute.configureAliases(aliases);
    instance.i18next.use(new LocaleBackend());
    return instance.setup({
      interpolation: {
        escapeValue: false,
      },
      attributes: aliases,
      lng: localStorage.getItem("rj-master-language") || 'fi',
      fallbackLng: 'en',
      debug: false,
      defaultNS: "server",
      fallbackNS: "translation",
    });
  });

  /* we had some issues with html-table when we went over 100 bindings eager handling threshold.
   * <tr repeat.for="row of rows & signal: signalName"> broke, it ceased listening to the rows computedProperty
   * events, or failed to draw the result, or whatever. */
  disableConnectQueue();

  aurelia.use.plugin(PLATFORM.moduleName('aurelia-deep-computed'));
  
  aurelia.use.plugin(PLATFORM.moduleName("aurelia-resize"));

  aurelia.use.plugin(PLATFORM.moduleName('aurelia-quill-plugin'), {
    modules: {
      toolbar: [
        [{ 'header': [1, 2, 3, false] }],
        ['bold', 'italic', 'underline'],
        ['link', 'image', { 'list': 'ordered'}, { 'list': 'bullet' }],
        ['clean'],
      ]
    },
    theme: 'snow',
  });

  aurelia.use.developmentLogging(environment.debug ? 'debug' : 'warn');

  if (environment.testing) {
    aurelia.use.plugin(PLATFORM.moduleName('aurelia-testing'));
  }

  await aurelia.start().then(() => aurelia.setRoot(PLATFORM.moduleName('app')));
}
